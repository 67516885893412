
import Vue from "vue";
import draggable from "vuedraggable";
import UsersPanelLoading from "@/components/Mentoree/UsersPanelLoading.vue";

export default Vue.extend({
  name: "UsersPanel",
  components: {
    draggable,
    UsersPanelLoading
  },
  props: {
    title: {
      type: String
    },
    users: {
      type: Array
    },
    isRating: {
      type: Boolean
    },
    editable: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      mentors: [] as any,
      hiddenMentors: [] as any,
      delayedDragging: false
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  mounted(): void {
    this.mentors = [...this.users];
  },
  methods: {
    // @ts-ignore
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      console.log(
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    getRoute(data: any) {
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      } else {
        return `/mentorees/${data.uuid}`;
      }
    }
  }
});
